'use client';

import {
  AssetType,
  EntityImageType,
  EntityLiveState,
  EntityType
} from '@/__generated__/API';
import { EntityItem, ParentEntityItem } from '@/api/entities/types';
import { AspectRatio } from '@/components/aspect-ratio';
import { Player, PlayerProps } from '@/components/player';
import { useRunBeforeUnload } from '@/hooks/use-run-before-unload';
import useStableCallback from '@/hooks/use-stable-callback';
import { playerStore } from '@/stores/player-store';
import { filterEntityRelationship } from '@/utils/filter-entity-relationship';
import { findEntityAssets, findEntityImages } from '@/utils/find-entity';
import { useLingui } from '@lingui/react';
import { MediaPauseEvent, MediaPlayEvent } from '@vidstack/react';
import { clsx } from 'clsx';
import compact from 'lodash-es/compact';
import round from 'lodash-es/round';
import { useCallback, useMemo } from 'react';
import { useGetRecentlyPlayed } from '../../api/recently-played/get';
import { useUpdateRecentlyPlayed } from '../../api/recently-played/update';
import './entity-playback.scss';
import Image from 'next/image';

type EntityPlaybackChildProps = Pick<
  PlayerProps,
  | 'title'
  | 'subtitle'
  | 'footer'
  | 'className'
  | 'poster'
  | 'locator'
  | 'assetType'
  | 'onPlay'
  | 'onPause'
  | 'onTimeUpdate'
  | 'disableMiniPlayer'
  | 'viewerCount'
  | 'requiredEntitlements'
  | 'enforceEntitlementsAt'
  | 'showPoster'
  | 'canShowEndedOverlay'
  | 'autoPlay'
  | 'parentEntityId'
  | 'pathname'
> & {
  childrenEntityIds: string[];
  entityId: string;
  entityType: EntityType;
  entityLiveState?: EntityLiveState;
  images: EntityItem['images'];
};

function EntityPlaybackChild({
  childrenEntityIds,
  parentEntityId,
  entityId,
  entityType,
  entityLiveState,
  requiredEntitlements,
  enforceEntitlementsAt,
  images,
  onPlay,
  onPause,
  ...playerProps
}: EntityPlaybackChildProps) {
  const { recentPlayed } = useGetRecentlyPlayed({
    parentEntityId,
    entityId
  });

  const { mutateAsync: updateProgress } = useUpdateRecentlyPlayed({
    entityId,
    parentEntityId,
    childrenEntityIds,
    entityType,
    images
  });

  const handleUpdateProgress = useCallback(async () => {
    const player = playerStore.get.playerInstance(entityId)?.ref?.current;
    if (player) {
      const progressPercentage = round(
        (player.currentTime / player.duration) * 100,
        2
      );
      await updateProgress(progressPercentage);
    }
  }, [updateProgress, entityId]);

  const { enable, disable } = useRunBeforeUnload(handleUpdateProgress, {
    runOnUnmount: true
  });

  const handlePlay = useStableCallback((nativeEvent: MediaPlayEvent) => {
    onPlay?.(nativeEvent);
    enable();
  });

  const handlePause = useStableCallback(
    async (nativeEvent: MediaPauseEvent) => {
      onPause?.(nativeEvent);
      await handleUpdateProgress();
      disable();
    }
  );

  const progressPercentage = recentPlayed?.playbackPercentage ?? 0;

  const isAudioPlayback = playerProps.assetType === AssetType.AUDIO_URL;

  function getShareUrl() {
    switch (entityType) {
      case EntityType.DAILYFIRE:
        return 'https://growthday.app.link/e/o3rjpxD3SQb';
      default:
        return '';
    }
  }

  return (
    <AspectRatio
      disabled={isAudioPlayback}
      minHeight={isAudioPlayback ? 164 : undefined}
    >
      <Player
        {...playerProps}
        entityId={entityId}
        parentEntityId={parentEntityId}
        initialProgress={progressPercentage}
        onPlay={handlePlay}
        onPause={handlePause}
        requiredEntitlements={requiredEntitlements}
        enforceEntitlementsAt={enforceEntitlementsAt}
        entityLiveState={entityLiveState}
        entityType={entityType}
        shareUrl={getShareUrl()}
        autoPlay={playerProps.autoPlay === true}
      />
    </AspectRatio>
  );
}

export type EntityPlaybackProps = Pick<
  PlayerProps,
  | 'title'
  | 'subtitle'
  | 'className'
  | 'onPlay'
  | 'onPause'
  | 'onTimeUpdate'
  | 'disableMiniPlayer'
  | 'viewerCount'
  | 'requiredEntitlements'
  | 'enforceEntitlementsAt'
  | 'showPoster'
  | 'canShowEndedOverlay'
  | 'autoPlay'
  | 'pathname'
> & {
  parentEntity?: ParentEntityItem | EntityItem | null;
  entity: EntityItem | ParentEntityItem;
};

export function EntityPlayback({
  parentEntity,
  entity,
  ...playerProps
}: EntityPlaybackProps) {
  const { i18n } = useLingui();

  const bannerImage = useMemo(() => {
    const image =
      findEntityImages(
        entity,
        EntityImageType.BANNER,
        EntityImageType.BACKGROUND
      )[0] ||
      findEntityImages(
        parentEntity,
        EntityImageType.BANNER,
        EntityImageType.BACKGROUND
      )[0];

    return image?.url;
  }, [entity, parentEntity]);

  const thumbnailImage = useMemo(() => {
    const image =
      findEntityImages(entity, EntityImageType.THUMBNAIL)[0] ||
      findEntityImages(parentEntity, EntityImageType.THUMBNAIL)[0];

    return image?.url;
  }, [entity, parentEntity]);

  const asset = useMemo(() => {
    return findEntityAssets(
      entity,
      AssetType.VIDEO_URL,
      AssetType.WISTIA_ID,
      AssetType.VIMEO_ID,
      AssetType.YOUTUBE_ID,
      AssetType.AUDIO_URL
    )[0];
  }, [entity]);

  const childrenEntityIds = useMemo(
    () =>
      filterEntityRelationship(parentEntity, {
        withoutChildren: true
      }).map((item) => item.id),
    [parentEntity]
  );

  if (!asset?.assetType || !asset?.locator) {
    const image = bannerImage || thumbnailImage;
    if (image) {
      return (
        <AspectRatio>
          <Image
            src={image}
            fill
            alt={entity.title ?? ''}
            style={{ objectFit: 'contain' }}
          />
        </AspectRatio>
      );
    }

    return null;
  }

  const entityType = (parentEntity?.entityType ||
    entity.entityType) as unknown as EntityType;

  const defaultTitle =
    entityType === EntityType.DAILYFIRE
      ? i18n.t({ id: 'entity.dailyFire', message: 'Daily Fire' })
      : entity.title;

  const defaultSubtitle =
    entityType === EntityType.DAILYFIRE ? entity.title : '';

  const defaultFooter =
    entityType === EntityType.DAILYFIRE ? entity.description : '';

  const requiredEntitlements =
    parentEntity?.requiredEntitlements || entity.requiredEntitlements;
  const enforceEntitlementsAt =
    parentEntity?.enforceEntitlementsAt || entity.enforceEntitlementsAt;

  return (
    <EntityPlaybackChild
      title={defaultTitle}
      subtitle={defaultSubtitle}
      footer={defaultFooter}
      {...playerProps}
      className={clsx(
        playerProps.className,
        'entity-player',
        entityType === EntityType.DAILYFIRE && 'daily-fire-player'
      )}
      images={parentEntity?.images || entity.images}
      entityType={entityType}
      entityLiveState={
        'liveState' in entity
          ? (entity.liveState as EntityLiveState) || undefined
          : undefined
      }
      entityId={entity.id}
      childrenEntityIds={childrenEntityIds}
      parentEntityId={parentEntity?.id}
      assetType={asset.assetType as unknown as AssetType}
      locator={asset.locator}
      poster={bannerImage}
      requiredEntitlements={compact(requiredEntitlements)}
      enforceEntitlementsAt={enforceEntitlementsAt as number | undefined}
    />
  );
}
